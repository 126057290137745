import React from "react"

const Gdynia = () => {
  return (
    <div className="privacy-content__content">
      <h3>REGULAMIN KONKURSU - GDYNIA</h3>
      <h4>
        „Zaprojektuj figurę świetlną i wygraj rodzinny wyjazd do Disneylandu”
      </h4>

      <h4>1. DEFINICJE</h4>
      <p>Ilekroć w niniejszym regulaminie jest mowa o:</p>
      <ol>
        <li>„Regulaminie” – należy przez to rozumieć niniejszy regulamin;</li>
        <li>
          „Organizatorze” – należy przez to rozumieć spółkę WHEN sp. z o.o. z
          siedzibą w Krakowie (ul. Fryderyka Chopina 18/3a, 30-047 Kraków,
          Polska), wpisaną do rejestru przedsiębiorców Krajowego Rejestru
          Sądowego pod numerem: 0000683438, posiadającą NIP: 6772421673 oraz
          numer REGON: 367571758, kapitał zakładowy: 10 000,00 zł, adres e-mail:
          hello@when.pl;
        </li>
        <li>
          „Fundatorze” – należy przez to rozumieć spółkę Wonderful Lighting sp.
          z o.o. z siedzibą w Gdyni (ul. Stryjska 24, 81-506 Gdynia, Polska),
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod
          numerem: 0000614792, posiadającą NIP: 9512412176 oraz numer REGON:
          364312163, kapitał zakładowy: 100 000,00 zł;
        </li>
        <li>
          „Konkursie” – należy przez to rozumieć konkurs przeprowadzany przez
          Organizatora opisany w Regulaminie;
        </li>
        <li>
          „Przedstawicielu Uczestnika” – należy przez to rozumieć
          przedstawiciela ustawowego Uczestnika poniżej 18 (osiemnastego) roku
          życia, w tym jego rodzica lub opiekuna prawnego, będącego pełnoletnią
          osobę fizyczną, posiadającą pełną zdolność do czynności prawnych,
          pełnię praw rodzicielskich, adres do doręczeń na terytorium
          Rzeczpospolitej Polskiej oraz posiadającą aktywne konto w serwisie
          Facebook, która przesyła Zgłoszenie Konkursowe wykonane przez
          Uczestnika;
        </li>
        <li>
          „Uczestniku” – należy przez to rozumieć osobę, która wykona Zadanie
          Konkursowe;
        </li>
        <li>
          „Ogrodzie Świateł” – należy przez to rozumieć Ogród Świateł w Gdyni
          (adres: Park Kolibki, Al. Zwycięstwa 291, Gdynia);
        </li>
        <li>
          „Jury” – należy przez to rozumieć komisję, o której mowa w pkt. 6.1.
          Regulaminu;
        </li>
        <li>
          „Zadaniu Konkursowym” – należy przez to rozumieć ogłoszone przez
          Organizatora zadanie do wykonania przez Uczestnika na zasadach
          określonych w Regulaminie;
        </li>
        <li>
          „Zgłoszeniu Konkursowym” – należy przez to rozumieć dzieło stworzone
          przez Uczestnika w celu wykonania Zadania Konkursowego i udostępnione
          przez Uczestnika lub Przedstawiciela Uczestnika na zasadach, w
          terminie i w formie wskazanej w Regulaminie;
        </li>
        <li>
          „Facebooku” – należy przez to rozumieć, zależnie od kontekstu, portal
          społecznościowy Facebook dostępny pod adresem www.facebook.com lub
          spółkę Meta Platforms, Inc. będącą właścicielem portalu
          społecznościowego Facebook;
        </li>
        <li>
          „Stronie na Facebooku Fundatora” – należy przez to rozumieć oficjalny
          profil Fundatora prowadzony w serwisie Facebook dostępny pod adresem:
          https://www.facebook.com/OgrodSwiatelGdynia
        </li>
        <li>
          „Nagrodzie” – należy przez to rozumieć nagrodę rzeczową, pieniężną lub
          inną przyznaną Zwycięzcy przez Fundatora w formie i o wartości
          wskazanej w Regulaminie;
        </li>
        <li>
          „Zwycięzcy” – należy przez to rozumieć Uczestnika, którego Zgłoszenie
          Konkursowe zostało wybrane przez Jury;
        </li>
        <li>
          „Kodeksie cywilnym” – należy przez to rozumieć ustawę z dnia 23
          kwietnia 1964 r. kodeks cywilny;
        </li>
        <li>
          „Prawie Autorskim” – należy przez to rozumieć ustawę z dnia 4 lutego
          1994 roku o prawie autorskim i prawach pokrewnych;
        </li>
        <li>
          „RODO” – należy przez to rozumieć Rozporządzenie Parlamentu
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
          danych).
        </li>
      </ol>

      <h4>2. POSTANOWIENIA OGÓLNE</h4>
      <ol>
        <li>
          Regulamin określa zasady uczestnictwa w Konkursie, a wszelkie
          publikowane przez Organizatora lub Fundatora materiały
          promocyjno-reklamowe mają jedynie charakter informacyjny.
        </li>
        <li>
          Regulamin jest dostępny na stronie internetowej pod adresem:
          https://ogrodswiatel.pl/regulaminy/. Odesłanie do Regulaminu zostaje
          zamieszczone także na Stronie na Facebooku Fundatora. Wszystkie
          informacje dotyczące Konkursu będą zamieszczane na stronie
          internetowej: www.konkurs.ogrodswiatel.pl
        </li>
        <li>
          Konkurs nie jest grą losową w rozumieniu art. 2 ustawy o grach
          hazardowych z dnia 19 listopada 2009 r.
        </li>
        <li>
          Ramy czasowe Konkursu określone są dla czasu środkowoeuropejskiego.
        </li>
        <li>
          Konkurs rozpoczyna się w dniu 12 grudnia 2022 roku o godzinie 12:00 i
          kończy się w dniu 26 lutego 2023 roku o godzinie 23:59.
        </li>
        <li>
          Konkurs skierowany jest do Uczestników w dwóch kategoriach wiekowych:{" "}
        </li>
        <ol>
          <li>
            kategoria wiekowa dla Uczestników do 13 (trzynastego) roku życia
            (tj. do trzynastego roku życia włącznie),
          </li>
          <li>
            kategoria wiekowa dla Uczestników powyżej 14 (czternastego) roku
            życia (tj. od 14 roku życia włącznie wzwyż).
          </li>
        </ol>
        <li>
          W każdej z kategorii wskazanych w pkt. 2.6. powyżej zostanie wybranych
          3 (trzech) Zwycięzców, którzy zajmą kolejno pierwsze, drugie i trzecie
          miejsce.
        </li>
        <li>
          W związku z obowiązującym Organizatora regulaminem portalu Facebook
          Organizator informuje, iż:
        </li>
        <ol>
          <li>
            Konkurs nie jest w żaden sposób sponsorowany, popierany ani
            przeprowadzany przez serwis Facebook, ani z nim związany;
          </li>
          <li>
            serwis Facebook nie ponosi odpowiedzialności w związku z Konkursem.
          </li>
        </ol>
        <li>
          Zadanie Konkursowe zostanie opublikowane na Stronie na Facebooku
          Fundatora nie później niż do dnia 12 grudnia 2022 roku.
        </li>
      </ol>

      <h4>3. WARUNKI UCZESTNICTWA W KONKURSIE</h4>
      <ol>
        <li>
          Uczestnictwo w Konkursie ma charakter dobrowolny i jest wolne od
          opłat.{" "}
        </li>
        <li>
          Warunkiem uczestnictwa w Konkursie jest wizyta Uczestnika w Ogrodzie
          Świateł w sezonie 2022/2023 i posiadanie dowodów zakupu biletów wstępu
          do Ogrodu Świateł.
        </li>
        <li>
          W Konkursie nie mogą brać udziału pracownicy Organizatora i inne osoby
          współpracujące z Organizatorem przy organizacji Konkursu, członkowie
          organów zarządzających Organizatora, podmioty i ich pracownicy
          współpracujący z Organizatorem przy organizowaniu Konkursu oraz
          członkowie najbliższych rodzin wymienionych osób, to jest małżonkowie,
          krewni i powinowaci w linii prostej (np. rodzice, dzieci, dziadkowie,
          wnuki), ich przysposobieni lub przysposabiający, a także rodzeństwo.
          Powyższe postanowienia stosują się odpowiednio do Fundatora i osób z
          nim związanych.
        </li>
        <li>
          Niedopuszczalne jest dokonywanie Zgłoszeń Konkursowych przez osobę
          niebędącą Uczestnikiem lub Przedstawicielem Uczestnika (w przypadku
          niepełnoletnich Uczestników).
        </li>
        <li>
          Konkurs jest organizowany za pośrednictwem portalu społecznościowego
          Facebook i w związku z tym, z przyczyn niezależnych od Organizatora,
          zarówno Przedstawiciel Uczestnika, Uczestnik, jak i Organizator
          podlegają regulacjom obowiązującym na tym portalu.
        </li>
        <li>
          Rezygnacja z udziału w Konkursie może nastąpić do końca trwania
          Konkursu, poprzez usunięcie całości lub części Zgłoszenia Konkursowego
          przez Uczestnika lub Przedstawiciela Uczestnika. W takim wypadku
          Zgłoszenie Konkursowe zostaje wykluczone z Konkursu niezależnie od
          przyczyn takiego usunięcia i tożsamości podmiotu usuwającego.
        </li>
        <li>
          Organizator ma prawo do zmiany całości lub części Zadania Konkursowego
          w dowolnym momencie przed zakończeniem Konkursu. W przypadku
          wystąpienia takiej zmiany Organizator poinformuje o tym fakcie
          Uczestników.
        </li>
        <li>
          Organizator ma prawo do zmiany terminów związanych z organizacją,
          przebiegiem i zakończeniem Konkursu (w tym daty rozpoczęcia i
          zakończenia Konkursu oraz wyłonienia Zwycięzców). W przypadku
          wystąpienia takiej zmiany Organizator poinformuje o tym fakcie
          Uczestników.
        </li>
      </ol>

      <h4>4. PRZEBIEG KONKURSU</h4>
      <ol>
        <li>
          Zadanie Konkursowe polega na sporządzeniu przez Uczestnika pracy
          plastycznej w dowolnej technice (np. rysunek, malunek, inny projekt),
          o dowolnej wybranej przez Uczestnika tematyce oraz jego opublikowaniu
          przez Uczestnika lub Przedstawiciela Uczestnika w formie komentarza
          pod postem zawierającym Zadanie Konkursowe na Stronie na Facebooku
          Fundatora, poprzez udostępnienie skanu lub zdjęcia tej pracy
          plastycznej. Skan lub zdjęcie pracy plastycznej Uczestnika muszą być
          wyraźne i bardzo dobrej jakości.
        </li>
        <li>
          Praca objęta Zgłoszeniem Konkursowym, o której mowa w pkt. 4.1.
          powyżej, musi być podpisana imieniem Uczestnika oraz wskazywać wiek
          Uczestnika (te informacje powinny zostać zamieszczone w prawym dolnym
          rogu pracy objętej Zgłoszeniem Konkursowym).
        </li>
        <li>
          Wysłanie Zgłoszenia Konkursowego jest jednoznaczne z akceptacją
          Regulaminu.
        </li>
        <li>
          Zgłoszenia do Konkursu są dokonywane wyłącznie w formie określonej w
          pkt. 4.1. powyżej.
        </li>
        <li>
          W przypadku niepełnoletnich Uczestników, którzy samodzielnie wyślą
          Zgłoszenie Konkursowe ich udział w Konkursie, ocena Zgłoszenia
          Konkursowego oraz przyznanie i wydanie Nagrody mogą zostać uzależnione
          od wyrażenia zgody na te działania przez Przedstawiciela Uczestnika.
        </li>
        <li>
          Każdy Uczestnik lub Przedstawiciel Uczestnika może wysłać wyłącznie
          jedno Zgłoszenie Konkursowe. W przypadku wysłania przez Uczestnika lub
          Przedstawiciela Uczestnika większej liczby Zgłoszeń Konkursowych Jury
          oceni jedynie pierwsze z przesłanych.
        </li>
      </ol>

      <h4>5. PRAWA NA DOBRACH NIEMATERIALNYCH DO ZGŁOSZENIA KONKURSOWEGO</h4>
      <ol>
        <li>
          Przesyłając Zgłoszenie Konkursowe Uczestnik lub Przedstawiciel
          Uczestnika potwierdza, że:
        </li>
        <ol>
          <li>
            zgłoszone przez niego Zgłoszenie Konkursowe, jest oryginalne,
            dotychczas niepublikowana i nie nagradzane w innych konkursach oraz
            jest autorstwa Uczestnika;
          </li>
          <li>
            jest osobą uprawnioną do dysponowania autorskimi prawami majątkowymi
            do Zgłoszenia Konkursowego w pełnym zakresie oraz prawa te nie są w
            żaden sposób ograniczone lub obciążone na rzecz osób trzecich;
          </li>
          <li>
            Zgłoszenie Konkursowe nie narusza dóbr osobistych, praw autorskich,
            praw pokrewnych oraz praw własności przemysłowej osób trzecich;
          </li>
          <li>
            korzystanie przez Organizatora i Fundatora ze Zgłoszenia
            Konkursowego w zakresie koniecznym dla realizacji Konkursu, jak
            również w zakresie określonym w Regulaminie (w szczególności w
            sposób opisany w pkt. 7.2.g. i 7.9. Regulaminu) nie będzie naruszało
            praw osób trzecich.
          </li>
        </ol>
        <li>
          W przypadku, jeżeli Zgłoszenie Konkursowe będzie stanowić utwór w
          rozumieniu Prawa Autorskiego z chwilą wysłania Zadania Konkursowego
          Uczestnik lub Przedstawiciel Uczestnika udziela Fundatorowi
          niewyłącznej, nieodpłatnej, zbywalnej, nieograniczonej terytorialnie
          licencji do tego utworu na wszelkich aktualnie znanych polach
          eksploatacji, w tym określonych w art. 50. i art. 74. Prawa
          Autorskiego, w tym:
        </li>
        <ol>
          <li>
            w zakresie przekazywania utworów za pośrednictwem sieci
            komputerowych, w tym poprzez Internet – w dowolnym standardzie,
            systemie i formacie (w tym w szczególności poprzez dowolne ich
            wykorzystywanie na stronach internetowych i portalach
            społecznościowych Fundatora i Organizatora);
          </li>
          <li>
            w zakresie obrotu oryginałem lub egzemplarzami – wprowadzanie do
            obrotu, w tym publiczne wystawianie i udostępnianie za pośrednictwem
            środków masowego przekazu, egzemplarzy utworów utrwalonych i
            zwielokrotnionych w sposób określony powyżej, jak również użyczenie
            lub najem i dzierżawa oryginału lub egzemplarzy;
          </li>
          <li>
            w zakresie rozpowszechniania utworów w sposób inny niż określony
            powyżej – publiczne udostępnianie w szczególności na
            ogólnodostępnych wystawach, przy prezentacji i reklamie w mediach
            tradycyjnych i elektronicznych, utrwalanie na nośnikach
            elektronicznych, publikacja w takich formach wydawniczych jak
            książki, albumy, broszury, a także wystawienie, wyświetlenie,
            odtworzenie, nadawanie i remitowanie (w tym także w postaci makiet)
            oraz publiczne udostępnianie utworów w taki sposób aby każdy mógł
            mieć do nich dostęp w miejscu i czasie przez siebie wybranym, oraz
            wyświetlanie utworów utrwalonych w formie zapisu audiowizualnego;
          </li>
          <li>
            w zakresie wykonywania wszelkich prac na podstawie utworów, w
            nieograniczonych ilościach wykorzystań i w dowolnych miejscach;
          </li>
          <li>
            w zakresie rozporządzania i korzystania z utworów zależnych w
            stosunku do utworu, w szczególności z wszelkich ich opracowań,
            adaptacji lub przeróbek;
          </li>
          <li>
            wykonywanie autorskich praw zależnych do utworu, w tym poprzez
            włączanie utworu do innych utworów (rozporządzanie i korzystanie z
            opracowań utworu) oraz na zezwalanie na korzystanie z opracowań
            Zgłoszenia Konkursowego i rozporządzanie tymi opracowaniami według
            uznania Fundatora;
          </li>
        </ol>
        <li>
          przy czym licencja udzielana jest na okres 10 (dziesięciu) lat (licząc
          od dnia dostarczenia Zgłoszenia Konkursowego) oraz obejmuje prawo do
          udzielania sublicencji w zakresie pól eksploatacji określonych
          powyżej. Po upływie okresu wskazanego w poprzednim zdaniu licencja
          przeradza się w licencję udzieloną na czas nieoznaczony.
        </li>
        <li>
          Fundator zastrzega na podstawie art. 921 §3 Kodeksu cywilnego, iż z
          chwilą przekazania Nagrody na warunkach wskazanych w pkt. 7.6. nabywa
          własność Zgłoszenia Konkursowego Zwycięzcy, a Zwycięzca w zamian za
          przyznanie Nagrody przenosi na Fundatora autorskie prawa majątkowe do
          Zgłoszenia Konkursowego na polach eksploatacji wskazanych w pkt. 5.2.
          powyżej. Zwycięzca zobowiązany jest do zawarcia pisemnej umowy
          przeniesienia autorskich praw majątkowych do Zgłoszenia Konkursowego
          na zasadach i warunkach wskazanych w zdaniu poprzedzającym na każde
          wezwanie Fundatora niezależnie od jego formy i terminu. Niniejsze
          postanowienie stosuje się odpowiednio w przypadku, w którym to
          Przedstawicielowi Uczestnika przysługuje prawo do rozporządzania
          prawami autorskimi do zwycięskiego Zgłoszenia Konkursowego Uczestnika.
        </li>
        <li>
          Z tytułu oświadczeń złożonych w pkt. 5.1. Uczestnik oraz
          Przedstawiciel Uczestnika ponoszą przed Fundatorem i Organizatorem
          odpowiedzialność gwarancyjną za to, że żadna osoba trzecia nie będzie
          podnosiła roszczeń wobec Fundatora i Organizatora. W szczególności w
          przypadku jakichkolwiek roszczeń ze strony osób trzecich Uczestnik lub
          Przedstawiciel Uczestnika, po zawiadomieniu przez Fundatora lub
          Organizatora, zobowiązani są zwolnić Fundatora i Organizatora z
          wszelkiej odpowiedzialności z tego tytułu oraz do całkowitego
          zaspokojenia uzasadnionych roszczeń osób trzecich, a także wstąpić w
          miejsce Fundatora lub Organizatora w każdym sporze sądowym lub w
          przypadku braku takiej możliwości - przystąpić po stronie Fundatora
          lub Organizatora do każdego sporu sądowego, a w przypadku zasądzenia
          jakichkolwiek kwot od Fundatora lub Organizatora, Uczestnik lub
          Przedstawiciel Uczestnika zobowiązani są zwrócić całość pokrytych
          roszczeń oraz wszelkie związane z tym wydatki i opłaty, włączając w to
          koszty procesu i koszty obsługi prawnej, a także naprawić wszelkie
          inne szkody wynikające z wyżej opisanych roszczeń osób trzecich.
        </li>
      </ol>

      <h4>6. ROZSTRZYGNIĘCIE KONKURSU</h4>
      <ol>
        <li>
          W celu wyłonienia zwycięzców Konkursu, Organizator powoła komisję
          (Jury), w skład której wejdą pracownicy Organizatora i Fundatora lub
          inne wyznaczone przez Organizatora i Fundatora osoby.
        </li>
        <li>
          Jury podda przesłane Zgłoszenia Konkursowe ocenie pod kątem spełniania
          następujących kryteriów:
        </li>
        <ol>
          <li>kreatywność;</li>
          <li>oryginalność;</li>
          <li>pomysłowość;</li>
        </ol>
        <li>
          Wyniki Konkursu zostaną ogłoszone do dnia 31 marca 2023 r. za
          pośrednictwem wybranych przez Organizatora kanałów komunikacji, w tym
          na wybranych mediach społecznościowych lub stronach internetowych
          Fundatora bądź Organizatora, na co Uczestnik oraz Przedstawiciel
          Uczestnika wyraża zgodę.
        </li>
        <li>Jury ma prawo do nie uzasadniania wyboru Zwycięzców. </li>
        <li>
          Zgłoszenia Konkursowe mogą być prezentowane na stronach internetowych
          i w mediach społecznościowych Organizatora oraz Fundatora, w tym w
          zestawieniach, podsumowaniach i przeglądach prezentujących przesyłane
          Zgłoszenia Konkursowe.
        </li>
        <li>
          Organizator ma prawo podania do wiadomości publicznej informacji
          dotyczących przebiegu Konkursu, za pomocą wybranych przez Organizatora
          mediów społecznościowych, a także na stronach internetowych Fundatora
          lub Organizatora, na co Uczestnik i Przedstawiciel Uczestnika wyrażają
          zgodę.
        </li>
      </ol>

      <h4>7. NAGRODY</h4>
      <ol>
        <li>Fundatorem Nagród jest Fundator.</li>
        <li>W Konkursie Zwycięzcom zostaną przyznane następujące Nagrody:</li>
        <ol>
          <li>
            Za zajęcie 1. (pierwszego) miejsca w kategorii wiekowej, o której
            mowa w pkt. 2.6.a. Regulaminu:
          </li>
          <ol>
            <li>
              voucher na rodzinny na wyjazd do Disneylandu dla 4 (czterech) osób
              (voucher obejmuje pokrycie kosztów noclegu, podróży, śniadania i
              biletów wejścia do Disneylandu),
            </li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.a.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Za zajęcie 2. (drugiego) miejsca w kategorii wiekowej, o której mowa
            w pkt. 2.6.a. Regulaminu:
          </li>
          <ol>
            <li>PlayStation 5,</li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.b.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Za zajęcie 3. (trzeciego) miejsca w kategorii wiekowej, o której
            mowa w pkt. 2.6.a. Regulaminu:
          </li>
          <ol>
            <li>Nintendo Switch OLED,</li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.c.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Za zajęcie 1. (pierwszego) miejsca w kategorii wiekowej, o której
            mowa w pkt. 2.6.b. Regulaminu:
          </li>
          <ol>
            <li>
              voucher na rodzinny wyjazd do Disneylandu dla 4 (czterech) osób
              (voucher obejmuje pokrycie kosztów noclegu, podróży, śniadania i
              biletów wejścia do Disneylandu),
            </li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.d.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Za zajęcie 2. (drugiego) miejsca w kategorii wiekowej, o której mowa
            w pkt. 2.6.b. Regulaminu:
          </li>
          <ol>
            <li>iPad Pro 11’’ 128 GB + Apple Pencil,</li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.e.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Za zajęcie 3. (trzeciego) miejsca w kategorii wiekowej, o której
            mowa w pkt. 2.6.b. Regulaminu:
          </li>
          <ol>
            <li>GoPro Hero BLACK 11,</li>
            <li>
              nagroda pieniężna w wysokości 11,11% wartości Nagrody, o której
              mowa w pkt. 7.2.f.i. powyżej (przyznana w celu sfinansowania
              podatku, o którym mowa w pkt. 7.3. poniżej).
            </li>
          </ol>
          <li>
            Ponadto, wszystkim Zwycięzcom wskazanym w pkt 7.2.a. – 7.2.f.
            powyżej przysługuje Nagroda w postaci zaprojektowania i wykonania
            przez Fundatora na podstawie Zgłoszenia Konkursowego Zwycięzcy
            figury świetlnej o rozmiarze do 100 (stu) centymetrów wysokości,
            która będzie dostępna dla zwiedzających w Ogrodzie Świateł w trakcie
            trwania wystawy w sezonie jesienno-zimowym 2023/2024.
          </li>
        </ol>
        <li>
          Zgodnie z art. 30 ust. 1 pkt. 2 ustawy z dnia 26 lipca 1991 r. o
          podatku dochodowym od osób fizycznych pobiera się zryczałtowany
          podatek dochodowy z tytułu wygranej w Konkursie. Podatek, o którym
          mowa w poprzednim zdaniu pobiera i odprowadza do odpowiedniego urzędu
          skarbowego Organizator. Nagrody pieniężne należne Zwycięzcom, o
          których mowa w pkt. 7.2.a.ii., 7.2.b.ii., 7.2.c.ii., 7.2.d.ii.,
          7.2.e.ii oraz 7.2.f.ii. powyżej zostaną przez Organizatora potrącone i
          przeznaczone na pokrycie podatku, o którym mowa w niniejszym
          postanowieniu.
        </li>
        <li>
          Nagroda należna jest wyłącznie Zwycięzcom, których Zgłoszenia
          Konkursowe zostaną wybrane przez Jury.
        </li>
        <li>
          Zwycięzcy zostaną powiadomieni o wygranej w terminie do 7 (siedmiu)
          dni roboczych od daty ogłoszenia wyników Konkursu, za pośrednictwem
          prywatnej wiadomości w serwisie Facebook lub innych, wybranych przez
          Organizatora środków komunikacji.
        </li>
        <li>
          Zwycięzcy zostaną poproszeni o podanie danych dotyczących Uczestnika
          lub Przedstawiciela Uczestnika, którego Zgłoszenie Konkursowe zostało
          wybrane przez Jury (w tym imię, nazwisko, adres zamieszkania lub inny
          adres właściwy do doręczeń, adres poczty elektronicznej lub numer
          telefonu) w celu umożliwienia Organizatorowi przekazania Nagrody. W
          przypadku niepełnoletnich Uczestników Konkursu to Przedstawiciel
          Uczestnika zobowiązany będzie do złożenia oświadczenia o wieku
          Uczestnika w formie, w zakresie i terminie wskazanym przez
          Organizatora. Nagrody zostaną przekazane lub udostępnione do odbioru
          Zwycięzcom w terminie do dnia 31 marca 2023 r., pod warunkiem
          wcześniejszego przekazania wszystkich danych i oświadczeń, których
          żądał Organizator.
        </li>
        <li>
          Przyznanie i wydanie Nagród Zwycięzcom może zostać uzależnione od:
          okazania Organizatorowi dowodów zakupu biletów do Ogrodu Świateł, o
          których mowa w pkt. 3.2. Regulaminu oraz od udzielenia wywiadu w dniu
          otwarcia Ogrodu Świateł w sezonie 2023/2024 (i wyrażenia zgody na
          wykorzystanie wizerunku utrwalonego w ramach tego wywiadu).{" "}
        </li>
        <li>
          Przedstawiciel Uczestnika będącego Zwycięzcą lub Uczestnik będący
          Zwycięzcą Konkursu zobowiązani są do udzielenia wywiadu w dniu
          otwarcia Ogrodu Świateł w sezonie 2023/2024, a także do wyrażenia
          zgody na wykonanie fotografii oraz nagrań audio i video, jak również
          na rozpowszechnianie ich wizerunku (utrwalonego w związku z odbiorem
          Nagrody, a także w ramach udzielanych wywiadów i wypowiedzi). Zgoda, o
          której mowa powyżej: (i) zostaje udzielona nieodpłatnie, bez
          ograniczeń czasowych i terytorialnych oraz (ii) obejmuje wielokrotne
          wykorzystywanie wizerunku, w tym w celach promocyjnych oraz
          marketingowych Organizatora i Fundatora Konkursu. Wykorzystywanie
          wizerunku obejmuje: (i) utrwalanie, obróbkę i zwielokrotnianie w
          dowolny sposób oraz (ii) rozpowszechnianie w całości lub w części, w
          dowolny sposób i formie, w tym za pośrednictwem stron internetowych i
          profili w mediach społecznościowych dotyczących Organizatora i
          Fundatora Konkursu. Zgoda, o której mowa powyżej może zostać
          wypowiedziana z zachowaniem 12-miesięcznego okresu wypowiedzenia ze
          skutkiem na koniec roku kalendarzowego. W razie odwołania zgody
          Organizator i Fundator nie będą zobowiązani do usunięcia z obrotu
          publicznego wprowadzonych już do obrotu egzemplarzy zawierających
          utrwalony wizerunek. Organizator i Fundator zobowiązują się do
          niewykorzystywania wizerunku ww. osób w sposób lub w kontekście
          naruszającym ich dobre imię.
        </li>
        <li>
          Nagrody nie podlegają zamianie na ich równowartość pieniężną ani
          zamianie na jakikolwiek inne świadczenie.
        </li>
        <li>
          Jeśli Uczestnik lub Przedstawiciel Uczestnika nie złoży oświadczeń lub
          nie poda danych, o których mowa w pkt. 7.6. powyżej w terminie do 30
          (trzydziestu) dni od dnia, w którym został o to poproszony lub nie
          odbierze przekazanej Nagrody w terminie do 30 (trzydziestu) dni od
          kiedy została mu udostępniona do odbioru, Uczestnik będący Zwycięzcą
          traci prawo do Nagrody, która następnie pozostaje do wyłącznej
          dyspozycji Organizatora.
        </li>
        <li>Figura świetlna, o której mowa w pkt. 7.2.g. powyżej:</li>
        <ol>
          <li>
            może być wykorzystywana przez Fundatora także w innych lokalizacjach
            i terminach niż określone w pkt. 7.2.g. powyżej;
          </li>
          <li>
            może zostać przygotowana jedynie w oparciu o inspirację zwycięskim
            Zgłoszeniem Konkursowym (Fundator ma prawo do wprowadzania zmian do
            zwycięskiego Zgłoszenia Konkursowego).
          </li>
        </ol>
      </ol>

      <h4>8. REKLAMACJE</h4>
      <ol>
        <li>
          Wszelkie reklamacje dotyczące Konkursu powinny być składane za
          pośrednictwem poczty elektronicznej, na adres e-mail: hello@when.pl
          lub za pomocą wiadomości prywatnej na portalu Facebook pod adresem:
          https://www.facebook.com/agencjawhen, nie później niż w terminie do 14
          (czternastu) dni od dnia od dnia ogłoszenia wyników Konkursu.
        </li>
        <li>
          Reklamacja powinna zawierać: imię, nazwisko, adres e-mail lub numer
          telefonu Przedstawiciela Uczestnika, jak również tytuł konkursu oraz
          dokładny opis i uzasadnienie reklamacji.
        </li>
        <li>
          Reklamacje nie zawierające danych, o których mowa w pkt 8.2 powyżej,
          skierowane w inny sposób niż określony w pkt 8.1. powyżej lub złożone
          po terminie, nie zostaną rozpatrzone.
        </li>
        <li>
          Reklamacje rozpatrywane będą przez komisję reklamacyjną powołaną przez
          Organizatora. Komisja reklamacyjna rozpatrywać będzie reklamacje na
          podstawie Regulaminu i obowiązujących przepisów prawa.
        </li>
        <li>
          Komisja reklamacyjna rozpatrzy reklamacje złożone zgodnie z pkt 8.1. i
          8.2 powyżej w terminie do 14 (czternastu) dni od dnia ich złożenia.
        </li>
        <li>
          Osoba zgłaszająca reklamację otrzyma decyzję komisji reklamacyjnej na
          adres e-mail podany w reklamacji.
        </li>
      </ol>

      <h4>9. OCHRONA DANYCH OSOBOWYCH</h4>
      <ol>
        <li>
          Organizator i Fundator przywiązują szczególną wagę do poszanowania
          prywatności Uczestników i Przedstawicieli Uczestników. Respektując
          przepisy prawa, a w szczególności RODO starannie dobierane i stosowane
          są odpowiednie środki techniczne i organizacyjne w celu zapewnienia
          należytej ochrony przetwarzanych danych osobowych. W szczególności
          dane są zabezpieczane przed ich udostępnieniem osobom nieupoważnionym,
          jak również przed ich przetwarzaniem z naruszeniem obowiązujących
          przepisów prawa. Dlatego też sprawowana jest permanentna kontrola nad
          procesem przetwarzania danych oraz limitowany jest dostęp do danych w
          możliwie największym stopniu m.in. poprzez udzielanie stosownych
          upoważnień na przetwarzanie tylko wówczas, gdy jest to niezbędne do
          prawidłowego przeprowadzenia Konkursu.{" "}
        </li>
        <li>Poprzez wzięcie udziału w Konkursie:</li>
        <ol>
          <li>
            Uczestnik będący osobą powyżej 16. (szesnastego) roku życia wyraża
            zgodę na przetwarzanie swoich danych osobowych przez Organizatora i
            Fundatora w celach wskazanych w pkt. 9.5.b. i 9.5.c. poniżej,
          </li>
          <li>
            Przedstawiciel Uczestnika działający w imieniu Uczestnika wyraża
            zgodę na przetwarzanie danych osobowych zarówno Przedstawiciela
            Uczestnika jak i Uczestnika przez Organizatora i Fundatora w celach
            wskazanych w pkt. 9.5.b i 9.5.c. poniżej.{" "}
          </li>
        </ol>
        <li>
          W przypadku Uczestnika poniżej 16. (szesnastego) roku życia, który
          samodzielnie przygotuje i wyśle Zgłoszenie Konkursowe przetwarzanie
          danych osobowych Uczestnika w celach wskazanych w pkt. 9.5.b i 9.5.c.
          poniżej może zostać oparte o następczą zgodę wyrażoną przez
          Przedstawiciela Uczestnika lub późniejsze potwierdzenie przez
          Przedstawiciela Uczestnika zgody wyrażonej wcześniej przez Uczestnika.
        </li>
        <li>
          Dane osobowe Przedstawicieli Uczestników i Uczestników są przetwarzane
          na podstawie zgody Uczestników lub Przedstawicieli Uczestników oraz
          ustawowych upoważnień zezwalających na przetwarzanie danych osobowych.
        </li>
        <li>Zgodnie z art. 13 i 14 RODO Organizator informuje, że:</li>
        <ol>
          <li>
            administratorem danych osobowych Przedstawicieli Uczestników i
            Uczestników Konkursu jest Fundator;
          </li>
          <li>
            dane osobowe Przedstawicieli Uczestników i Uczestników przetwarzane
            będą na podstawie art. 6 ust 1 lit. a) RODO – w celu przeprowadzenia
            i rozstrzygnięcia Konkursu oraz promowania działalności Fundatora i
            Organizatora;
          </li>
          <li>
            dane osobowe Zwycięzców przetwarzane będą na podstawie art. 6 ust 1
            lit. b) i c) RODO – w celu przyznania, wydania i rozliczania Nagród,
            zakończenia Konkursu oraz w celu wykonania ciążących na Fundatorze
            obowiązków podatkowych i rachunkowo-księgowych;
          </li>
          <li>
            odbiorcami danych osobowych Przedstawicieli Uczestników i
            Uczestników będą:
          </li>
          <ol>
            <li>Organizator;</li>
            <li>
              zewnętrzny podmiot prowadzący obsługę informatyczną Organizatora i
              Fundatora;
            </li>
            <li>
              użytkownicy stron internetowych i mediów społecznościowych
              Organizatora i Fundatora oraz adresaci folderów informacyjnych,
              materiałów promocyjnych Organizatora i Fundatora;
            </li>
            <li>odbiorcy uprawnieni z mocy prawa do ich otrzymania.</li>
          </ol>
          <li>odbiorcami danych osobowych Zwycięzców będą:</li>
          <ol>
            <li>Organizator;</li>
            <li>
              zewnętrzne biuro księgowe prowadzące dla Fundatora dokumentację
              podatkowo-rachunkowo;
            </li>
            <li>
              zewnętrzny podmiot prowadzący obsługę informatyczną Organizatora i
              Fundatora;
            </li>
            <li>
              użytkownicy stron internetowych Organizatora i Fundatora oraz
              adresaci folderów informacyjnych, materiałów promocyjnych
              Organizatora i Fundatora;
            </li>
            <li>odbiorcy uprawnieni z mocy prawa do ich otrzymania.</li>
          </ol>
          <li>
            Dane osobowe Przedstawicieli Uczestników i Uczestników nie będą
            przetwarzane w sposób zautomatyzowany ani poddawane profilowaniu,
            ani też nie będą wysyłane do krajów trzecich (tj. poza Europejski
            Obszar Gospodarczy) lub do organizacji międzynarodowych;
          </li>
          <li>
            Dane Przedstawicieli Uczestników i Uczestników będą przechowywane
            przez okres nie dłuższy niż jest to konieczne do realizacji celu ich
            przetwarzania w postaci przeprowadzenia Konkursu, a następnie
            zostaną usunięte.
          </li>
          <li>
            Dane osobowe Zwycięzców będą przechowywane przez okres 5 (pięciu)
            lat od dnia powstania obowiązku podatkowego, licząc od końca roku
            kalendarzowego, w którym nastąpiło nabycie Nagrody.
          </li>
          <li>
            Przedstawiciele Uczestników posiadają prawo dostępu do treści danych
            oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,
            prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do
            cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem
            przetwarzania, którego dokonano na podstawie zgody przed jej
            cofnięciem (o ile prawo takie przysługuje mu na podstawie przepisów
            prawa). Wykonanie prawa do cofnięcia zgody jest równoznaczne z
            rezygnacją udziału w Konkursie.
          </li>
          <li>
            Przedstawiciel Uczestnika ma prawo wniesienia skargi do Prezesa
            Urzędu Ochrony Danych Osobowych, gdy uzna, iż przetwarzanie danych
            osobowych dotyczących Przedstawiciela Uczestnika lub Uczestnika
            narusza przepisy prawa, a w szczególności RODO.
          </li>
        </ol>
        <li>
          Zbieranie danych osobowych odbywa się na zasadzie pełnej
          dobrowolności, jednak podanie danych osobowych stanowi warunek
          uczestnictwa Konkursie. Niepodanie danych osobowych (lub podanie
          fałszywych danych osobowych) może spowodować wykluczenie z Konkursu
          lub wstrzymanie wydania Nagrody do czasu podania prawidłowych danych
          osobowych.
        </li>
        <li>
          We wszelkich sprawach związanych z ochroną danych osobowych
          Przedstawicieli Uczestników, Uczestników oraz Zwycięzców Konkursu
          można kontaktować się za pośrednictwem poczty elektronicznej na adres
          e-mail: hello@when.pl.
        </li>
        <li>
          Organizator może podjąć środki prowadzące do weryfikacji podanych
          danych osobowych w przypadku powzięcia podejrzenia, że podane dane są
          nieprawdziwe, lub też powzięcia podejrzenia, iż podejmowane są
          działania naruszające postanowienia Regulaminu lub prawa.
        </li>
        <li>
          Na czas trwania Konkursu i 5 (pięć) lat po zakończeniu Konkursu
          Uczestnik oraz Przedstawiciel Uczestnika wyrażają zgodę na
          wykorzystywanie przez Organizatora i Fundatora wizerunku Uczestnika
          oraz Przedstawiciela Uczestnika w celu umieszczenia ich na stronach
          internetowych, w folderach informacyjnych, materiałach promocyjnych i
          profilach w mediach społecznościowych Organizatora i Fundatora w celu
          informowania o Konkursie oraz kolejnych edycjach Konkursu.
        </li>
      </ol>

      <h4>10. ŚWIADCZENIE USŁUG DROGĄ ELEKTRONICZNĄ</h4>
      <ol>
        <li>
          W razie świadczenia przez Organizatora usług drogą elektroniczną
          Regulamin stanowi regulamin, o którym mowa w art. 8. ustawy z dnia 18
          lipca 2002 roku o świadczeniu usług drogą elektroniczną w zakresie
          tych usług.
        </li>
        <li>
          Dla współpracy z systemem teleinformatycznym, którym posługuje się
          Organizator – Przedstawiciel Uczestnika musi dysponować sprzętem i
          systemem spełniającym następujące wymagania techniczne:
        </li>
        <ol>
          <li>
            urządzenie pozwalające przeglądanie witryn internetowych z dostępem
            do Internetu;
          </li>
          <li>
            zaktualizowana przeglądarka internetowa w najnowszej stabilnej
            wersji dostarczanej przez producenta;
          </li>
          <li>
            włączona obsługa cookies, a także zainstalowane odpowiednie dodatki
            do przeglądarki pozwalające na obsługę Java, Javascript, Adobe
            Flash;
          </li>
          <li>
            wyłączone oprogramowanie blokujące wykonywanie skryptów powyższych
            aplikacji;
          </li>
          <li>aktywne konto poczty elektronicznej (e-mail);</li>
          <li>aktywne konto w serwisie Facebook.</li>
        </ol>
        <li>
          Zakazane jest, w ramach Konkursu dostarczanie przez Przedstawicieli
          Uczestników i Uczestników treści o charakterze bezprawnym,
          naruszającym dobra osobiste lub prawa autorskie osób trzecich, lub
          naruszające w jakikolwiek sposób obowiązujące normy społeczne i
          obyczajowe, zasady współżycia społecznego w Internecie (netykieta). W
          szczególności zakazane jest:
        </li>
        <ol>
          <li>
            propagowanie ustrojów totalitarnych, symboli z nimi związanych,
            nawoływanie do nienawiści lub popełnienia przestępstwa;
          </li>
          <li>
            zamieszczanie treści wulgarnych, lub zawierających wulgaryzmy, lub
            treści powszechnie uznanych za obraźliwe;
          </li>
          <li>
            atakowanie, zastraszanie i nękanie innych uczestników Konkursu;
          </li>
          <li>
            zamieszczanie i posługiwanie się danymi osobowymi innych osób oraz
            ich wizerunkami bez stosownego upoważnienia;
          </li>
          <li>dostarczanie szkodliwego oprogramowania;</li>
          <li>
            zamieszczanie treści propagujących używanie narkotyków, lub
            substancji psychoaktywnych, nadużywanie alkoholu lub uprawianie
            hazardu, zawierających nagość lub pornografię oraz drastyczną i
            nieuzasadnioną przemoc, a także jakichkolwiek innych zachowań,
            których propagowanie jest zabronione;
          </li>
          <li>
            działanie o charakterze spamerskim, a także mające znamiona czynu
            nieuczciwej konkurencji lub nieuczciwej praktyki rynkowej.
          </li>
          <li>
            zamieszczanie treści podważających dobre imię lub renomę
            Organizatora, Fundatora lub osób trzecich;
          </li>
          <li>
            zamieszczanie odnośników do stron i plików zawierających treści
            wskazane w pkt. 10.3.a – 10.3.h. powyżej.
          </li>
        </ol>
        <li>
          Uczestnik oraz Przedstawiciel Uczestnika ponoszą odpowiedzialność za
          udostępniane przez siebie treści podczas Konkursu. W przypadku
          umieszczania przez Uczestnika lub Przedstawiciela Uczestnika treści
          zabronionych prawem lub Regulaminem, Organizator upoważniony jest do
          ich usunięcia, a także do wykluczenia Uczestnika lub Przedstawiciela
          Uczestnika z Konkursu.
        </li>
        <li>
          Celem uczynienia zadość wymogom ustawy, o której mowa w pkt. 10.1.
          powyżej, Organizator informuje, iż korzystanie z usług świadczonych
          drogą elektroniczną za pośrednictwem sieci Internet wiąże się z
          ryzykiem. Podstawowym zagrożeniem jest możliwość zainfekowania systemu
          teleinformatycznego przez tzw. wirusy, robaki i konie trojańskie. By
          uniknąć zagrożeń z tym związanych zaleca się, by osoby korzystające z
          sieci Internet zainstalowały na swoim urządzeniu oprogramowanie
          antywirusowe i na bieżąco je aktualizowały.
        </li>
        <li>
          W przypadku umieszczenia w Zadaniu Konkursowym lub na Stronie na
          Facebooku Fundatora odnośników (linków) do innych stron internetowych,
          Organizator zastrzega, że nie ponosi odpowiedzialności za treści
          zamieszczone na tych stronach.
        </li>
        <li>Organizator i Fundator nie ponoszą odpowiedzialności za:</li>
        <ol>
          <li>treść Zgłoszeń Konkursowych; </li>
          <li>
            brak możliwości kontaktu ze Zwycięzcą, w tym z powodu podania przez
            niego nieprawdziwych lub błędnych danych albo ich niepodania;
          </li>
          <li>
            brak możliwości wydania Zwycięzcy Nagrody, w tym z powodu błędnego
            podania przez Zwycięzcę danych adresowych lub kontaktowych, odmowy
            odebrania przez Zwycięzcę Nagrody w sposób, w miejscu lub w terminie
            wskazanym przez Organizatora lub Fundatora.
          </li>
        </ol>
      </ol>

      <h4>11. POSTANOWIENIA KOŃCOWE</h4>
      <ol>
        <li>
          Praw oraz obowiązków związanych z uczestnictwem w Konkursie, a także
          prawa do żądania wydania Nagrody lub realizacji świadczeń będących
          przedmiotem Nagrody, nie można przenosić na osoby trzecie.
        </li>
        <li>
          Powołane w treści Regulaminu bez bliższego oznaczenia numery punktów
          (pkt) oznaczają odpowiednie jednostki redakcyjne Regulaminu.
        </li>
        <li>Regulamin podlega wyłącznie prawu polskiemu.</li>
        <li>Regulamin wchodzi w życie w dniu 12 grudnia 2022 roku.</li>
      </ol>
    </div>
  )
}

export default Gdynia
