import React from "react"

const Privacy = () => {
  return (
    <div className="privacy-content__content">
      <p>Informacje podstawowe dotyczące przetwarzania danych osobowych</p>
      <h4>1. Administrator danych</h4>
      <p>
        Wonderful Lighting Sp. z o.o. z siedzibą w Gdyni (81-506) przy ul.
        Stryjskiej 24 NIP: 9512412176 (dalej: Wonderful Lighting).
      </p>

      <h4>2. Dane kontaktowe</h4>
      <p>
        Z Wonderful Lighting można się skontaktować poprzez adres e-mail:
        biuro@wonderful-co.eu , lub pisemnie: Wonderful Lighting Sp. z o.o.
        Gdynia 81-506, ul. Stryjska 24. Wonderful Lighting wyznaczył inspektora
        ochrony danych, kontakt: iod@cd-lighting.pl.
      </p>

      <h4>
        3. Cele przetwarzania oraz podstawa prawna przetwarzania oraz czas
        przetwarzania.
      </h4>
      <p>
        Pani/Pana dane będą przetwarzane w celu realizacji zamówionych usług
        takich jak np. udział w świetlnych wystawach, czy realizacja usługi
        „Newsletter Ogród Świateł” oraz w odpowiedzi na Państwa kontakt z nami
        (np. za pomocą formularza kontaktowego, e-mailem lub telefonicznie).
      </p>
      <p>
        Czas przetwarzania: do czasu realizacji usług lub żądania z
        zastrzeżeniem okresu przetwarzania niezbędnego do realizacji obowiązków
        prawnych oraz prawa do dochodzenia, wykonania, obrony ewentualnych
        roszczeń.
      </p>

      <h4>4. Dane dostępowe i hosting</h4>
      <p>
        Na nasze strony internetowe można wchodzić bez podawania swoich danych
        osobowych. W przypadku każdego wywołania strony internetowej serwer
        zapisuje automatycznie jedynie tzw. logi serwera, jak np. nazwę żądanego
        pliku, Państwa adres IP, datę i godzinę wywołania, ilość przesyłanych
        danych i składającego zapytanie dostawcę usług internetowych (dane
        dostępowe) oraz dokumentuje wywołanie strony.
      </p>
      <p>
        Dane te są analizowane wyłącznie w celu zapewnienia prawidłowego
        funkcjonowania strony i ulepszania naszej oferty. Powyższe służy – w
        ramach oceny interesów – zabezpieczeniu naszego prawnie uzasadnionego
        interesu, polegającego na prawidłowym zaprezentowaniu naszej oferty.
        Wszystkie dane dostępowe są usuwane w terminie siedmiu dni od momentu
        zakończenia Państwa wizyty na stronie.
      </p>

      <h4>5. Usługi hostingowe dostarczane przez zewnętrznego usługodawcę</h4>
      <p>
        W ramach powierzenia przetwarzania danych – na nasze zlecenie zewnętrzny
        usługodawca wykonuje dla nas usługi w zakresie hostingu i prezentacji
        stron internetowych. Wszystkie dane, które – w sposób opisany w
        niniejszej polityce prywatności – zostały zgromadzone w ramach
        korzystania z naszej strony internetowej lub w przewidzianych do tego
        celu formularzach , są przechowywane na serwerach tego usługodawcy.
        Przetwarzanie na innych serwerach odbywa się wyłącznie w zakresie
        określonym w niniejszej polityce prywatności. Usługodawca ten ma
        siedzibę na terenie kraju należącego do Unii Europejskiej lub
        Europejskiego Obszaru Gospodarczego.
      </p>

      <h4>6. Przekazywanie danych</h4>
      <p>
        Pani/Pana dane mogą być przekazywane w celu realizacji umowy, podmiotom
        przetwarzającym dane osobowe wyłącznie na zlecenie Wonderful Lighting ,
        przy czym takie podmioty będą przetwarzać dane na podstawie umowy z
        Wonderful Lighting i wyłącznie zgodnie z poleceniami Wonderful Lighting.
      </p>
      <p>Twoje dane mogą być przekazywane następującym podmiotom:</p>
      <ul>
        <li>
          platformom płatności współpracującym z Wonderful Lighting w celu
          realizacji płatności internetowej
        </li>
        <li>
          procesorom w związku ze zleconymi przez Wonderful Lighting działaniami
          realizowanymi w imieniu Wonderful Lighting
        </li>
        <li>
          kancelariom prawnym, którym Wonderful Lighting zlecił np. prowadzenie
          postępowania
        </li>
        <li>
          podmiotom współpracującym z Wonderful Lighting : Facebook, Google,
          Youtube, Google Analytics, które mogą zamieszczać własne pliki cookies
          (informacje o zewnętrznych plikach cookies mogą Państwo znaleźć na
          stronach internetowych podmiotów współpracujących).
        </li>
        <li>
          podmiotom lub organom uprawnionym na podstawie przepisów prawa. 6.
        </li>
      </ul>
      <p>Przekazywanie danych poza Europejski Obszar Gospodarczy</p>
      <ul>
        <li>
          Pani/Pana dane nie będą przekazywane do odbiorców znajdujących się w
          państwach poza Europejskim Obszarem Gospodarczym.
        </li>
      </ul>

      <h4>7. Prawa osoby, której dane dotyczą</h4>
      <p>Przysługuje Pani/Panu prawo:</p>
      <ul>
        <li>dostępu do Pani/Pana danych</li>
        <li>żądania ich sprostowania</li>
        <li>żądania ich usunięcia</li>
        <li>żądania ograniczenia przetwarzania, wycofania zgody</li>
        <li>
          do przenoszenia danych osobowych, tj. do otrzymania od Wonderful
          Lighting Pani/Pana danych osobowych w pliku o powszechnie używanym
          formacie
        </li>
        <li>
          wniesienia skargi do organu nadzorczego zajmującego się ochroną danych
          osobowych.
        </li>
      </ul>
      <p>
        W celu realizacji powyżej wymienionych praw należy skontaktować się z
        Wonderful Lighting. Można to zrobić np. przesyłając wniosek na adres
        siedziby Wonderful Lighting lub e-mailowo na adres
        biuro@wonderful-co.eu.
      </p>

      <h4>8. obrowolność podania danych</h4>
      <p>
        Gromadzimy dane osobowe wyłącznie wtedy, kiedy nam je Państwo
        dobrowolnie przekazują zamawiając usługę, kontaktując się z nami (np. za
        pomocą formularza kontaktowego lub e-mailem). Przetwarzamy dane
        niezbędne do wykonania umowy lub rozpatrzenia sprawy, w której
        kontaktowali się Państwo.
      </p>

      <h4>9. Podjęte środki ochrony danych</h4>
      <p>
        Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących
        przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych
        serwerach.
      </p>
      <p>
        Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym
        celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji
        (SSL).
      </p>
      <p>
        Wdrożyliśmy szyfrowanie danych oraz mamy wprowadzoną kontrolę dostępu
        dzięki czemu minimalizujemy skutki ewentualnego naruszenia
        bezpieczeństwa danych.
      </p>

      <h4>10. Pliki cookies</h4>
      <p>
        Witryna ogrodswiatel.pl używa cookies. Są to niewielkie pliki tekstowe
        wysyłane przez serwer www i przechowywane przez oprogramowanie komputera
        przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną, witryna
        rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry
        pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi,
        który je utworzył. Cookies ułatwiają więc korzystanie z wcześniej
        odwiedzonych witryn. Gromadzone informacje dotyczą adresu IP, typu
        wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego,
        dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji
        oraz informacji przesyłanych do witryny za pośrednictwem formularza
        kontaktowego.
      </p>
      <p>
        Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
        użytkownicy korzystają z naszych witryn, aby usprawniać funkcjonowanie
        serwisu zapewniając bardziej efektywną i bezproblemową nawigację.
        Monitorowania informacji o użytkownikach dokonujemy korzystając z
        narzędzia Google Analitics, które rejestruje zachowanie użytkownika na
        stronie. Cookies identyfikuje użytkownika, co pozwala na dopasowanie
        treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego
        preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego
        reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy standard
        wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie
        wewnątrz firmy Wonderful Lighting w celu optymalizacji działań.
      </p>
      <p>Na naszej witrynie wykorzystujemy następujące pliki cookies:</p>
      <ul>
        <li>
          „niezbędne” pliki cookies, umożliwiające korzystanie z usług
          dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
          wykorzystywane do usług wymagających uwierzytelniania w ramach
          serwisu;
        </li>
        <li>
          pliki cookies służące do zapewnienia bezpieczeństwa, np.
          wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
          ramach serwisu;
        </li>
        <li>
          „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o
          sposobie korzystania ze stron internetowych serwisu;
        </li>
        <li>
          „reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom
          treści reklamowych bardziej dostosowanych do ich zainteresowań.
        </li>
      </ul>
      <p>
        Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia
        opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce
        internetowej. Instrukcja zarządzania plikami cookies jest dostępna na
        stronie: allaboutcookies.org
      </p>
      <p>
        W przypadku braku zgody na wykorzystywanie plików cookies funkcjonalność
        naszej strony internetowej może zostać ograniczona.
      </p>
      <p>
        Wonderful Lighting Sp. z o.o. zastrzega sobie prawo do zmiany Polityki
        prywatności z następujących, ważnych przyczyn:
      </p>
      <ul>
        <li>
          ze względu na zmianę przepisów powszechnie obowiązującego prawa,
        </li>
        <li>
          w celu realizacji obowiązku wynikającego z prawomocnego orzeczenia
          sądu lub decyzji organów administracji,
        </li>
        <li>
          ze względów bezpieczeństwa, w tym mających na celu uniemożliwienie
          korzystania z Serwisów w sposób sprzeczny z przepisami prawa lub z
          Regulaminem,
        </li>
        <li>
          wprowadzenia istotnych zmian w funkcjonowaniu Strony lub którejkolwiek
          z Usług, w tym związanych z postępem technicznym lub technologicznym,
          w tym w sytuacji rozpoczęcia świadczenia nowych Usług, udostępnienia
          nowych funkcjonalności lub zaprzestania świadczenia dotychczasowych
          Usług lub funkcjonalności istotnie zmieniających zakres świadczonych
          Usług.
        </li>
      </ul>
      <p>
        Każdorazowa zmiana będzie obwieszczona poprzez opublikowanie nowego
        Regulaminu na stronie.
      </p>
    </div>
  )
}

export default Privacy
