import React from "react"

import Layout from "components/Layout"

import { Content } from "page_components/privacy"

const Policy = () => {
  const lang = "pl"

  return (
    <Layout
      seo={{
        title: "Regulamin i polityka prywatności",
        description: "",
      }}
      lang={lang}
    >
      <Content lang={lang} />
    </Layout>
  )
}

export default Policy
