/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useState, useEffect } from "react"
import * as queryString from "query-string"

import { Privacy, Chorzow, Warszawa, Krakow, Gdynia } from "./components"

const Content = () => {
  const [tab, setTab] = useState("privacy")

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location : null

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    setTab(parsed.city ? parsed.city : "privacy")
  }, [])

  return (
    <section className="privacy-content">
      <div className="container">
        <h1>Regulamin i polityka prywatności</h1>

        <div className="row">
          <div className="col-lg-3">
            <div className="privacy-content__nav">
              <button
                onClick={() => setTab("privacy")}
                className={tab === "privacy" ? "current" : ""}
              >
                Polityka prywatności
              </button>
              <button
                onClick={() => setTab("chorzow")}
                className={tab === "chorzow" ? "current" : ""}
              >
                Regulamin konkursu CHORZÓW
              </button>
              <button
                onClick={() => setTab("warszawa")}
                className={tab === "warszawa" ? "current" : ""}
              >
                Regulamin konkursu WARSZAWA
              </button>
              <button
                onClick={() => setTab("krakow")}
                className={tab === "krakow" ? "current" : ""}
              >
                Regulamin konkursu KRAKÓW
              </button>
              <button
                onClick={() => setTab("gdynia")}
                className={tab === "gdynia" ? "current" : ""}
              >
                Regulamin konkursu GDYNIA
              </button>
            </div>
          </div>

          <div className="col-lg-8 offset-lg-1">
            {tab === "chorzow" ? (
              <Chorzow />
            ) : tab === "warszawa" ? (
              <Warszawa />
            ) : tab === "krakow" ? (
              <Krakow />
            ) : tab === "gdynia" ? (
              <Gdynia />
            ) : (
              <Privacy />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
